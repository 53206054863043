import React from "react";
import { Link } from "gatsby";

import { BaseQueryType } from "models/graphql/base";
import { ContactType } from "models/graphql/contact";
import { Banner, Icon, GoogleMapComponent } from "components/layout_v2/index";

export default ({ data }: BaseQueryType<ContactType>) => {
  const _data = data?.allPrismicContact.edges[0].node.data;
  const { name, banner, email, address, address_geo, phone, phone2 } = _data;

  if (!_data) return null;

  return (
    <>
      {!!name.text && !!banner.url && <Banner title={name.text} backgroundImage={banner.url} />}

      {(!!name || !!email || !!address || !!phone || !!phone2) && (
        <section className="contact-info-area ptb-80">
          <div className="container">
            <div className="row">
              {!!email && (
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-box">
                    <div className="icon">
                      <Icon name="Mail" />
                    </div>
                    <h3>Email</h3>
                    <p>
                      <Link to={email}>{email}</Link>
                    </p>
                  </div>
                </div>
              )}

              {!!address && (
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-box">
                    <div className="icon">
                      <Icon name="Map" />
                    </div>
                    <h3>Address</h3>
                    <p>{address}</p>
                  </div>
                </div>
              )}

              {(!!phone || !!phone2) && (
                <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                  <div className="contact-info-box">
                    <div className="icon">
                      <Icon name="Phone" />
                    </div>
                    <h3>Telephone</h3>
                    {!!phone && (
                      <p>
                        <Link to="#">{phone}</Link>
                      </p>
                    )}

                    {!!phone2 && (
                      <p>
                        <Link to="#">{phone2}</Link>
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      <section className="contact-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>Get In Touch With Us</h2>
          </div>

          <div className="row h-100 justify-content-center align-items-center">
            {!!address_geo.latitude && !!address_geo.longitude && (
              <div className="col-lg-6 col-md-12">
                <GoogleMapComponent
                  center={{ lng: address_geo.longitude, lat: address_geo.latitude }}
                />
              </div>
            )}

            <div className="col-lg-6 col-md-12">
              <form id="contactForm">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        required
                        data-error="Please enter your name"
                        placeholder="Name"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        required
                        data-error="Please enter your email"
                        placeholder="Email"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Subject"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        cols={30}
                        rows={5}
                        required
                        data-error="Write your message"
                        placeholder="Your Message"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <button type="submit" className="btn btn-primary">
                      Send Message
                    </button>

                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
