import React from 'react'
import {PageSliceSection} from 'models/graphql'

export default ({ primary }: PageSliceSection) => {
  return (
      <section id="overview" className="section-base section-top-overflow">
        <div className="container">
          <br/>
          <hr className="space-lg"/>
          <div className="row">
            <div className="col-lg-6 order-md-first">
              <h2>{primary.title.text}</h2>
              <p>
                {primary.content.text}
              </p>
              <hr className="space-sm visible-md"/>
            </div>
            <div className="col-lg-6" data-anima="fade-right" data-time="1000">
              <img className="margin-11" src={primary.image.url} alt={primary.image.alt}/>
            </div>
          </div>
          <br/>
        </div>
      </section>
  )
}