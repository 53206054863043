import React from "react";
import { navigate } from "gatsby";

import { NavigationWrapperType } from "models/graphql";
import Switch from "components/Switch";
import { AuthContext } from "contexts";
import styled from "styled-components";
import GatsbyLink from "gatsby-link";

const LogoutBtn = styled.button`
  background-color: transparent;
  border: none;
  padding: 0 15px;
  height: 60px;
  line-height: 60px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  font-family: "Muli", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  color: #1e5631;

  &:hover {
    color: #4c9a2a;
  }

  span {
    margin-left: 5px;
  }
`;

export default ({ pageContext }: NavigationWrapperType) => {
  const menuItems = pageContext.menuItems;

  const onClick = (e) => {
    e.preventDefault();
  };

  const { isLoggedIn, logout } = React.useContext(AuthContext);

  return (
    <nav
      className="menu-classic menu-fixed menu-transparent menu-one-page align-right light"
      data-menu-anima="fade-bottom"
      data-scroll-detect="true"
      style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)" }}
    >
      <div className="container">
        <div className="menu-brand">
          <a href="/">
            <img
              className="logo-default"
              src="/media/logos/lighthouse-logo.png"
              alt="project-lighthouse-logo"
              height={75}
            />
          </a>
        </div>
        <i className="menu-btn" />
        <div className="menu-cnt">
          <ul id="nav-links">
            {menuItems
              .filter((menuItem) => menuItem.primary.published)
              .map((menuItem, index) => {
                if (menuItem.items.length > 0) {
                  return (
                    <li key={`key__menu__${menuItem.primary.page}__${index}`} className="dropdown">
                      <a onClick={onClick}>Resources</a>
                      <ul>
                        {menuItem.items.map((v, i) => (
                          <li key={`key__subMenu__${i}`}>
                            <a href={`/${v.path}`}>{v.label}</a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li key={`key__menu__${menuItem.primary.page}__${index}`} className="nav-link">
                      {!menuItem.primary.is_dropdown && (
                        <a key={`key__menuLink__${index}`} href={`/${menuItem.primary.path || ""}`}>
                          {menuItem.primary.label}
                        </a>
                      )}
                      {menuItem.primary.is_dropdown && (
                        <a key={`key__menu__${index}`} onClick={onClick}>
                          {menuItem.primary.label}
                        </a>
                      )}
                    </li>
                  );
                }
              })}
            <Switch condition={isLoggedIn}>
              <li className="nav-link">
                <LogoutBtn
                  onClick={() => {
                    logout();
                    navigate("/app/auth/login", { state: { from: window.location.pathname } });
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                    <path
                      d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4M16 17l5-5-5-5M21 12H9"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Logout</span>
                </LogoutBtn>
              </li>
            </Switch>
            <Switch condition={!isLoggedIn}>
              <li>
                <GatsbyLink to="/app/auth/login">Sign in</GatsbyLink>
              </li>
              <li className="nav-link">
                <GatsbyLink to="/app/auth/register">Sign up</GatsbyLink>
              </li>
            </Switch>
          </ul>
          <div className="clear" />
        </div>
      </div>
    </nav>
  );
};
