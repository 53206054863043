import React from 'react'
import { PageSliceCallout } from 'models/graphql'

export default ({ primary }: PageSliceCallout) => {
  return (
    <section className="section-block section-block-full section-block-right section-callout">
      <div className="block-media">
        <div className="image" data-parallax="scroll" data-bleed="0" data-image-src={primary.background_image.url} />
      </div>
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>{primary.title.text}</h2>
          </div>
          <div className="col" />
        </div>
      </div>
    </section>
  )
}
