import React from "react";
import { GlobalPageType } from "models/graphql/page";

import { Blog } from "components/layout_v2/index";

export default ({ data }: GlobalPageType) => {
  const _data = data?.allPrismicNews.edges;
  const pageInfo = data?.allPrismicNews?.pageInfo;

  if (!_data || !pageInfo) return null;

  const blogContent = _data.map(({ node }) => {
    const { data: nodeData, uid } = node;

    return {
      link: `/news/${uid}`,
      date: nodeData.date,
      title: nodeData.title.text,
      imageSrc: nodeData.image.url,
      description: nodeData.summary.text,
    };
  });

  return (
    <>
      <Blog content={blogContent} pageInfo={pageInfo} />
    </>
  );
};
