import React from "react";

import { BaseQueryType } from "models/graphql/base";
import { FaqsType } from "models/graphql/faqs";
import { Banner, AccordionEl } from "components/layout_v2/index";

export default ({ data }: BaseQueryType<FaqsType>) => {
  const _data = data?.allPrismicFaqs.edges[0].node.data;
  const { name, banner, body = [] } = _data;

  const [faqsBody] = body;

  const content = faqsBody?.items.map(({ title, content }) => ({
    title,
    description: content.text,
  }));

  if (!_data) return null;

  return (
    <>
      {!!name.text && !!banner.url && <Banner title={name.text} backgroundImage={banner.url} />}
      <AccordionEl content={content} />
    </>
  );
};
