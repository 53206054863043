import React from 'react'
import { Link } from 'gatsby'
import { ArrowRight } from 'react-feather'

import { BaseQueryType, NewsType, PageContextType } from 'models/graphql'

export default ({ slice, data }: BaseQueryType<NewsType> & PageContextType) => {
  const newsArray = data?.allPrismicNews.edges
  const limit = data?.allPrismicPage?.edges[0]?.node?.data?.body[6]?.primary?.limit || 3

  return (
    <section className="blog-area ptb-80">
      <div className="container">
        {!!slice.primary.title.text && (
          <div className="section-title">
            <h2>{slice.primary.title.text}</h2>
          </div>
        )}

        <div className="row">
          {newsArray.slice(0, limit).map(
            (v) =>
              v.node.data.featured && (
                <div key={`key__${v.node.uid}`} className="col-lg-4 col-md-6">
                  <div className="single-blog-post">
                    <div className="blog-image">
                      <Link to={`/news/${v.node.uid}`}>
                        <img src={v.node.data.image.url} alt={v.node.data.image.alt} />
                      </Link>
                    </div>

                    <div className="blog-post-content">
                      <span>{v.node.data.date}</span>

                      <h3>
                        <Link to={`/news/${v.node.uid}`}>{v.node.data.title.text}</Link>
                      </h3>

                      <p>{v.node.data.summary.text}</p>

                      <Link to={`/news/${v.node.uid}`} className="read-more-btn">
                        Read More <ArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </section>
  )
}
