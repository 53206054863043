import React from "react";
import { graphql } from "gatsby";
import {
  GlobalPageType,
  PageSectionTypes,
  PageSliceActionSection,
  PageSliceCallout,
  PageSliceCategory,
  PageSliceComponents,
  PageSliceFeature,
  PageSliceBanner,
  PageSliceNews,
  PageSlicePlain,
  PageSliceSection,
  PageSliceSectionWithColumn,
  PageSliceSlidingHero,
} from "models/graphql";
import {
  Callout,
  Contact,
  Faqs,
  News,
  NewsItems,
  Section,
  SectionWithColumn,
} from "components/slices";
import {
  Layout,
  Banner,
  DownloadCards,
  Stakeholders,
  Content,
  BoxAreas2,
  MainBanner,
  BoxAreas,
  ContentWithCTA,
} from "components/layout_v2/index";

export const Page = ({ data, pageContext }: GlobalPageType) => {
  const _data = data?.allPrismicPage.edges[0]?.node.data;
  const contact_data = data?.allPrismicContact.edges[0]?.node.data;

  if (!_data) return null;

  return (
    <Layout pageContext={pageContext} contact={contact_data}>
      {_data.body.map((slice: PageSectionTypes, index) => {
        const sliceKey = `key__sliceHeader__${index}`;
        switch (slice.slice_type) {
          case "sliding_hero":
            const slidingHeroSlice = slice as PageSliceSlidingHero;
            return <MainBanner key={sliceKey} {...slidingHeroSlice} />;
          case "hero":
            const bannerSlice = slice as PageSliceBanner;
            return (
              <Banner
                key={sliceKey}
                title={bannerSlice.primary.title.text}
                backgroundImage={bannerSlice.primary.image.url}
              />
            );
        }
      })}

      {_data.body.map((slice: PageSectionTypes, index) => {
        const sliceKey = `key__slice__${index}`;
        switch (slice.slice_type) {
          case "feature":
            const featureSlice = slice as PageSliceFeature;
            return <BoxAreas key={sliceKey} {...featureSlice} />;
          case "callout":
            const calloutSlice = slice as PageSliceCallout;
            return <Callout key={sliceKey} {...calloutSlice} />;
          case "section":
            const sectionSlice = slice as PageSliceSection;
            return <Section key={sliceKey} {...sectionSlice} />;
          case "action_section":
            const actionSectionSlice = slice as PageSliceActionSection;
            return <ContentWithCTA key={sliceKey} {...actionSectionSlice} />;
          case "news":
            const newsSlice = slice as PageSliceNews;
            return <News key={sliceKey} {...{ slice: newsSlice, data }} />;
          case "section_with_column":
            const sectionWithColumnSlice = slice as PageSliceSectionWithColumn;
            return <SectionWithColumn key={sliceKey} {...sectionWithColumnSlice} />;
          case "plain":
            const plainSlice = slice as PageSlicePlain;
            return <Content key={sliceKey} {...plainSlice} />;
          case "category":
            const categorySlice = slice as PageSliceCategory;
            return <BoxAreas2 key={sliceKey} {...categorySlice} />;
          case "components":
            const componentSlice = slice as PageSliceComponents;
            const componentKey = sliceKey;
            switch (componentSlice.primary.component) {
              case "stakeholders":
                return <Stakeholders key={componentKey} {...{ data: data }} />;
              case "news":
                return <NewsItems key={componentKey} {...{ data: data }} />;
              case "faqs":
                return <Faqs key={componentKey} {...{ data: data }} />;
              case "contact":
                return <Contact key={componentKey} {...{ data: data }} />;
              case "reports":
                return <DownloadCards key={componentKey} {...{ data: data }} />;
              default:
                return <p key={componentKey} className="one" />;
            }
          default:
            return null;
        }
      })}
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($page: String, $pageNumber: Int) {
    allPrismicPage(filter: { uid: { eq: $page } }) {
      edges {
        node {
          data {
            name {
              text
            }
            body {
              ... on PrismicPageBodyFeature {
                id
                slice_type
                items {
                  icon
                  title {
                    text
                  }
                  content {
                    text
                  }
                }
              }
              ... on PrismicPageBodyCallout {
                id
                primary {
                  title {
                    text
                  }
                  content {
                    text
                  }
                  background_image {
                    alt
                    url
                  }
                }
                slice_type
              }
              ... on PrismicPageBodySection {
                id
                primary {
                  title {
                    text
                  }
                  content {
                    text
                  }
                  image {
                    alt
                    url
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyActionSection {
                id
                primary {
                  title {
                    text
                  }
                  content {
                    text
                  }
                  display_button
                  button_label
                  button_action_path
                  supporting_text
                }
                slice_type
              }
              ... on PrismicPageBodyNews {
                id
                slice_type
                primary {
                  limit
                  title {
                    text
                  }
                }
              }
              ... on PrismicPageBodyComponents {
                slice_type
                primary {
                  component
                }
                id
                items {
                  key
                  link {
                    url
                  }
                  description {
                    text
                  }
                }
              }
              ... on PrismicPageBodySlidingHero {
                id
                primary {
                  title {
                    text
                  }
                  sub_title {
                    text
                  }
                  button_label
                  button_link
                  show_button
                  image {
                    url
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyHero {
                id
                primary {
                  title {
                    text
                  }
                  image {
                    alt
                    url
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyPlain {
                id
                primary {
                  title {
                    text
                  }
                  content {
                    html
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyCategory {
                id
                primary {
                  title {
                    text
                  }
                }
                items {
                  title {
                    text
                  }
                  content {
                    text
                  }
                  icon
                }
                slice_type
              }
              ... on PrismicPageBodySectionWithColumn {
                id
                primary {
                  first_column {
                    text
                    html
                  }
                  second_column {
                    text
                    html
                  }
                  image {
                    url
                    alt
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
    allPrismicNews(filter: { data: { published: { eq: true } } }, limit: 9, skip: $pageNumber) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            summary {
              text
            }
            body {
              html
            }
            image {
              url
              alt
            }
            date(formatString: "MMM D, YYYY")
            featured
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
    allPrismicStakeholders {
      edges {
        node {
          data {
            title {
              text
            }
            items {
              image {
                url
                alt
              }
            }
          }
          uid
        }
      }
    }
    allPrismicContact {
      edges {
        node {
          data {
            address_geo {
              latitude
              longitude
            }
            email
            facebook
            address
            instagram
            linkedin
            banner {
              url
            }
            phone
            phone2
            twitter
            name {
              text
            }
          }
          uid
        }
      }
    }
    allPrismicFaqs {
      edges {
        node {
          data {
            body {
              ... on PrismicFaqsBodyGroup {
                id
                slice_type
                items {
                  title
                  content {
                    text
                    html
                  }
                }
              }
            }
            name {
              text
            }
            banner {
              url
            }
          }
        }
      }
    }
  }
`;
