import React from 'react'
import { PageSliceSectionWithColumn } from 'models/graphql'

export default ({ primary }: PageSliceSectionWithColumn) => {
  return (
    <section className="section-base section-color">
      <div className="container">
        <div className="row row-fit-lg">
          <div className="col-lg-3">
            <p>{primary.first_column.text}</p>
          </div>
          <div className="col-lg-3">
            <p>{primary.second_column.text}</p>
          </div>
          <div className="col-lg-1" />
          <div className="col-lg-5">
            <img
              className="margin-23 margin-md-23"
              src={primary.image.url}
              alt={primary.image.alt}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
